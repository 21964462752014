<template>
  <v-container>
    <v-row>
      <v-col cols="12" xs="12" sm="5" md="3" lg="3">
        <v-navigation-drawer permanent width="100%">
          <v-list>
            <div v-for="(item, i) in items" :key="i">

              <v-list-item
                v-if="!item.children"
                :to="item.to"
                avatar
                class="v-list-item"
                exact
              >
                <v-list-item-title v-text="item.text"/>
              </v-list-item>

              <v-list-group
                v-else
                :key="item.text"
                no-action
                append-icon="mdi-plus"
                :value="false"
              >
                <template v-slot:activator>
                  <v-list-item-title>{{ item.text }}</v-list-item-title>
                </template>

                <v-list-item
                  v-for="child in item.children"
                  :to="child.to"
                  :key="child.text"
                >
                  <v-list-item-title>{{ child.text }}</v-list-item-title>
                </v-list-item>

              </v-list-group>

            </div>

          </v-list>

        </v-navigation-drawer>
      </v-col>
      <v-col cols="12" xs="12" sm="7" md="9" lg="9">
        <router-view></router-view>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
    import {mapState} from 'vuex';
    import CustomerInfo from "./profile/CustomerInfo";
    import AuthorInfo from "./profile/AuthorInfo";
    import DefaultInfo from "./profile/DefaultInfo";

    export default {
        name: 'Home',
        data() {
            return {
                component: '',
                items: [
                    {
                        to: {
                            name: 'MyAccount'
                        },
                        text: 'My account',
                    },
                    {
                        to: {
                            name: 'MyWishlist'
                        },
                        text: 'My Wishlist',
                    },
                    {
                        to: {
                            name: 'MyOrdersHistory'
                        },
                        text: 'My Orders History',
                    },
                    {
                        to: '#',
                        text: 'Online order payment',
                    },
                ],
            }
        },

        computed: {
            ...mapState({
                'user': state => state.auth.user,
            })
        },

        mounted() {
            this.component = this.defineComponent()
        },

        methods: {
            defineComponent() {
                let component = this.capitalize(`${this.user.role}Info`);
                if (this.existsComponent(component)) {
                    return component;
                }
                return 'DefaultInfo';
            },

            existsComponent(component) {
                return this.$options.components[component] !== undefined;
            },

            capitalize(word) {
                return word.charAt(0).toUpperCase() + word.slice(1);
            }
        },

        components: {
            CustomerInfo,
            AuthorInfo,
            DefaultInfo
        }

    }
</script>
